@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?2wwb2');
  src:  url('../fonts/icomoon.eot?2wwb2#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?2wwb2') format('truetype'),
    url('../fonts/icomoon.woff?2wwb2') format('woff'),
    url('../fonts/icomoon.svg?2wwb2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-download:before {
  content: "\e900";
}
.icon-headphones-with-microphone:before {
  content: "\e901";
}
.icon-assortiment:before {
  content: "\e902";
}
.icon-experience:before {
  content: "\e903";
}
.icon-fast-order-handle:before {
  content: "\e904";
}
.icon-gear:before {
  content: "\e905";
}
.icon-handshake:before {
  content: "\e906";
}
.icon-location:before {
  content: "\e907";
}
.icon-quality:before {
  content: "\e908";
}
.icon-quality-personal:before {
  content: "\e909";
}
.icon-sale:before {
  content: "\e90a";
}
.icon-software:before {
  content: "\e90b";
}
