@import url(../stylesheet/iconmoon.css);
@font-face {
  font-family: "OpenSans Regular";
  src: url("../fonts/OpenSans/OpenSansRegular/OpenSansRegular.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans Bold";
  src: url("../fonts/OpenSans/OpenSansBold/OpenSansBold.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans SemiBold";
  src: url("../fonts/OpenSans/OpenSansSemiBold/OpenSansSemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans Light";
  src: url("../fonts/OpenSans/OpenSansLight/OpenSansLight.ttf") format("truetype");
}

/* top */
#top #top-left-links ul li a,
#top #top-left-links ul li a:visited {
  border: transparent;
}

#top #top-left-links ul li a:hover,
#top #top-right-links > ul > li:hover {
  border-bottom: transparent;
}

/* language */
button.btn.btn-link.dropdown-toggle.btn-language {
  margin-bottom: 3px;
}

#top .btn-link.btn-language i:first-child,
#top .btn-link.btn-currency i:first-child {
  top: 0;
  font-size: 14px;
}

#top .btn-link.btn-language i:first-child {
  font-size: 12px;
}

button.btn.btn-link.dropdown-toggle.btn-language span {
  font-size: 14px;
}

.btn-language > span {
  margin-right: 4px;
}

.list-inline a span {
  font-size: 14px;
}

/* header */
header {
  padding: 15px 0 20px;
}

/* logo */
#logo {
  margin: 0;
}

/* search */
.ts-search-box {
  align-self: flex-start;
  margin-top: 0;
}

#search .cats-button {
  display: none;
}

.search-slogan {
  font-size: 14px;
  color: #fff;
  text-transform: initial;
  text-align: center;
}

#search #oct-search-button {
  padding: 10px 16px;
  background: #FF5500;
}

#search #oct-search-button i {
  color: #fff;
}

#search #oct-search-button:hover {
  background: #fff;
}

#search #oct-search-button:hover i {
  color: #333333;
}

/* phones */
.phones-top-box {
  margin-bottom: 0;
}

.phones-top-box .dropdown-toggle {
  display: flex;
  align-items: center;
  font-weight: normal;
}

.phones-top-box .dropdown-toggle .fa-phone {
  transform: rotateY(160deg);
  font-size: 21px;
}

.phones-top-box .show-phones {
  color: #FF5500 !important;
}

.phone-list {
  margin: 10px 0 0;
  list-style-type: none;
}

.phone-list li a {
  color: #fff;
  font-family: "OpenSans Regular", sans-serif;
  font-weight: normal;
}

.phone-list li i {
  color: #fff;
  font-size: 14px;
  margin: 0 8px 0 0;
}

/* cart */
#cart:hover {
  background: #fff;
}

#cart:hover #cart-total .cart-total-price {
  color: #333333;
}

#cart:hover i {
  color: #333333;
}

#cart:hover i span {
  background: #333333;
}

.buttons-top-box {
  margin-bottom: -2px;
}

.buttons-top-box div i span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #2c2c2c;
}

#menu .nav > li > a {
  text-transform: initial;
  font-family: "OpenSans Regular", sans-serif;
  font-size: 16px;
}

#oct-bluring-box .menu-row {
  background: #FF5500 !important;
  border: none;
}

#menu .dropdown-inner a {
  font-size: 14px;
}

#menu .see-all {
  color: #FF5500 !important;
}

.menu-row {
  margin-bottom: 0;
}

.menu-row.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9997;
  animation: smoothScroll .25s ease;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

footer {
  padding: 0;
  margin-top: 26px;
}

footer .footer-consultation {
  display: none;
  background: #FF5500;
  padding: 30px 0;
}

footer .footer-consultation .consultation-row {
  display: flex;
  align-items: center;
}

footer .footer-consultation .consultation-row .consultation-title {
  font-family: "OpenSans SemiBold", sans-serif;
  font-size: 26px;
}

footer .footer-consultation .consultation-row .consultation-text {
  margin: 0;
  font-family: "OpenSans Regular", sans-serif;
  font-size: 22px;
  line-height: 26px;
}

footer .footer-consultation .consultation-row .consultation-btn {
  display: block;
  padding: 14px 0;
  margin: 0;
  text-align: center;
  font-size: 14px;
  background: #fff;
  color: #000 !important;
}

footer .footer-middle {
  padding: 30px 0;
}

footer .footer-middle .first-row {
  margin: 0 0 30px;
}

footer .footer-middle .first-row .h5 {
  margin-left: -15px;
  font-family: "OpenSans Regular", sans-serif;
  font-size: 19px;
  line-height: 26px;
  color: #fff;
}

footer .footer-middle .first-row .actions button {
  background: transparent;
}

footer .footer-middle a {
  font-size: 14px;
}

footer .footer-middle a:hover {
  text-decoration: none;
}

footer .footer-middle .oct-copy a {
  color: #cccccc !important;
  font-size: 12px;
}

footer .footer-middle .oct-copy a:hover {
  color: #FF5500 !important;
}

.common-home .oct-slideshow-box {
  padding: 0;
}

.common-home .oct-slideshow-box .owl-wrapper-outer {
  padding: 0;
}

.common-home .oct-slideshow-box .item {
  padding: 0;
}

.common-home h2:not(.popup-header) {
  line-height: 40px;
  text-align: center;
  font-family: 'OpenSans Bold', sans-serif;
  text-transform: initial;
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 50px;
  position: relative;
}

.common-home h2:not(.popup-header):before, .common-home h2:not(.popup-header):after {
  display: none;
}

.common-home .cat-wall-row {
  margin: 50px -15px 72px;
}

.common-home .cat-wall-row .oct-carousel-header {
  display: none;
}

.common-home .cat-wall-row .cat-wall-row-side-text {
  padding: 40px 10px 42px 18px;
  background: #2c2c2c;
}

.common-home .cat-wall-row .cat-wall-row-side-text .cat-wall-company {
  line-height: 26px;
  font-family: "OpenSans Bold", sans-serif;
  font-size: 22px;
  color: #fff;
}

.common-home .cat-wall-row .cat-wall-row-side-text .cat-wall-company-add {
  font-family: "OpenSans Regular", sans-serif;
  font-size: 14px;
  color: #fff;
}

.common-home .cat-wall-row .cat-wall-row-side-text a {
  color: #FF5500;
  text-decoration: underline;
}

.common-home .cat-wall-row .cat-wall-row-items .cat-wall-row-item-wrapper {
  margin: 0 0 32px;
}

.common-home .cat-wall-row .cat-wall-row-items .cat-wall-row-item-wrapper .cat-wall-row-item {
  height: 160px;
  border: 2px solid transparent;
  overflow: hidden;
  transition: all .25s ease;
}

.common-home .cat-wall-row .cat-wall-row-items .cat-wall-row-item-wrapper .cat-wall-row-item:hover {
  border: 2px solid #FF5500;
  transition: all .25s ease;
}

.common-home .cat-wall-row .cat-wall-row-items .cat-wall-row-item-wrapper:nth-child(4),
.common-home .cat-wall-row .cat-wall-row-items .cat-wall-row-item-wrapper:nth-child(5),
.common-home .cat-wall-row .cat-wall-row-items .cat-wall-row-item-wrapper:nth-child(6) {
  margin: 0;
}

.common-home .about-text-box {
  padding: 0 0 18px;
  margin-top: 0;
  margin-bottom: 36px;
  box-shadow: none;
}

.common-home .about-text-box h2 {
  margin-bottom: 0;
}

.common-home .about-text-box p {
  color: #000;
  text-align: left;
  font-size: 16px;
}

.common-home .advantages-container {
  display: flex;
  flex-flow: row wrap;
}

.common-home .advantages-container .advantages-item {
  width: 20%;
  min-height: 200px;
  padding: 0 15px;
  margin: 0 0 32px;
  text-align: center;
}

.common-home .advantages-container .advantages-icon {
  position: relative;
  display: block;
  margin: 0 auto 15px;
  width: 131px;
  height: 131px;
}

.common-home .advantages-container .advantages-icon .advantages-icon-outer-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid gray;
  border-radius: 50%;
}

.common-home .advantages-container .advantages-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px;
  font-size: 56px;
  border: 1px solid red;
  border-radius: 50%;
}

.common-home .advantages-container .advantages-text {
  font-size: 16px;
}

.common-home .oct-carousel-header {
  font-family: 'OpenSans Bold', sans-serif;
  text-transform: initial;
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 44px;
}

.common-home .oct-carousel-header:after {
  display: none;
}

.common-home .oct-carousel-row .oct-carousel-box {
  box-shadow: none;
}

.common-home .oct-carousel-row .oct-carousel-box {
  padding: 20px 0;
}

.common-home .oct-carousel-box .news-item {
  margin: 0 8px;
}

.common-home .oct-carousel-box .news-item .image {
  padding-left: 0;
  padding-right: 0;
}

.common-home .news-row .oct-carousel-box .item .news-item .name a:hover {
  color: #FF5500 !important;
}

.common-home .oct-carousel-box .news-item .read-more a:hover {
  color: #333333;
}

.common-home .oct-carousel-row .owl-carousel .owl-item .news-item .name a {
  height: 42px;
  margin-bottom: 30px;
  font-family: "OpenSans Bold", sans-serif;
  font-size: 18px;
}

.common-home .oct-carousel-row .owl-carousel .owl-item .name a:hover {
  color: #2c2c2c !important;
  text-decoration: none;
}

.common-home .news-desc {
  font-size: 14px;
}

.common-home .read-more {
  padding: 0 20px;
  margin: 12px 0 0;
}

.common-home .read-more a {
  color: #FF5500;
  text-decoration: underline;
}

.common-home .footer-consultation {
  display: block;
}

#sstore-3-level ul:first-child li.has-sub > a.toggle-a:before,
#sstore-3-level ul:first-child li.has-sub > a.toggle-a:after {
  background: #fff !important;
}

#sstore-3-level ul > li.has-sub > a.toggle-a:before,
#sstore-3-level ul > li.has-sub > a.toggle-a:after {
  background: #fff !important;
}

#sstore-3-level ul > li.has-sub.open > a.toggle-a:after,
#sstore-3-level ul > li.has-sub.open > a.toggle-a:before {
  background: #fff !important;
}

#sstore-3-level ul > li.has-sub li.has-sub > a.toggle-a:after,
#sstore-3-level ul > li.has-sub li.has-sub > a.toggle-a:before {
  background: #000 !important;
}

#sstore-3-level ul ul ul li a:hover {
  color: #000;
}

.product-grid .product-thumb {
  text-align: center;
  padding: 0 0 12px;
}

.product-grid .product-thumb h4 {
  height: 62px;
  margin-top: 6px;
}

.product-grid .product-thumb .cart a {
  display: block;
}

.product-grid .product-thumb:hover .oct-product-desc {
  display: none;
}

/* hover effect */
.product-grid .product-thumb .cat-box-effect {
  height: 80px;
}

.product-thumb .cat-model {
  margin-top: 6px;
  display: none;
}

.product-thumb .oct-product-stock {
  display: none;
}

.product-thumb h4 a {
  font-size: 15px;
}

.product-thumb .oct-product-desc {
  display: none;
}

.product-thumb .rating {
  display: none;
}

.product-thumb .image .quick-view a:hover {
  text-decoration: none;
}

/* additional hover image */
.product-grid .image .hover-image,
.product-list .image .hover-image {
  transition: all .6s ease-in-out;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
}

.product-grid .image:hover .hover-image,
.product-list .image:hover .hover-image {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
  transition: all .6s ease;
}

/* filter */
.mfilter-box .box-heading {
  background: #fff;
}

.mfilter-opts-container {
  background: #fff;
}

.after-header-item {
  text-align: left;
}

.product-variation {
  margin: 0 0 6px;
  font-size: 16px;
}

.product-price-static {
  display: flex;
  align-items: flex-end;
  margin: 0 0 9px;
}

.product-price-static span {
  margin-right: 4px;
  font-size: 16px;
  font-family: "OpenSans SemiBold", sans-serif;
}

.product-price-static .oct-price-normal-static {
  margin: 0;
  line-height: 22px;
  font-size: 18px;
}

.product-price {
  display: flex;
  align-items: flex-end;
  margin: 0 0 11px;
}

.product-price span {
  margin-right: 4px;
  font-size: 16px;
  font-family: "OpenSans SemiBold", sans-serif;
}

.product-price h2 {
  line-height: 22px;
  font-size: 18px;
  font-family: "OpenSans Regular", sans-serif;
}

.number {
  display: flex;
  padding: 0;
  margin: 0 0 24px;
}

.number .oct-btn-disabled {
  display: none;
  opacity: 0.2;
}

.number .number-text {
  line-height: 18px;
  margin: -1px 0 0;
  text-transform: capitalize;
  color: #333333;
  font-family: "OpenSans SemiBold", sans-serif;
  font-size: 16px;
}

.number .frame-change-count {
  display: flex;
  width: auto;
}

.number .frame-change-count input.plus-minus,
.number .frame-change-count .plus-minus-buffer {
  display: block;
  height: 16px;
  line-height: 16px;
  padding: 0;
  margin: 0 4px;
  background: none;
  font-family: "OpenSans Regular", sans-serif;
  font-size: 18px;
  color: #333333;
}

.number .frame-change-count input.plus-minus {
  width: 100%;
  max-width: 15px;
}

.number .frame-change-count span {
  display: block;
  font-size: 18px;
  line-height: 16px;
  color: #333333;
  font-family: "OpenSans Regular", sans-serif;
}

a.oct-button.button-one-click {
  width: 50%;
}

a.oct-button.button-wishlist,
a.oct-button.button-compare {
  display: none;
}

.page_product table td, .page_product table th {
  padding: 4px 6px;
}

/* discount */
.product-discount {
  margin: 16px 0 0;
}

.product-discount-item {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0 0 10px;
}

input.product-discount-radio {
  vertical-align: middle;
  margin: 0 6px 0 0;
}

input.product-discount-radio:hover {
  cursor: pointer;
}

.product-discount-btns {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0;
}

.product-discount-btns-block {
  position: relative;
  width: 22px;
  height: 22px;
  margin: 0 6px 0 0;
}

.product-discount-btns-block input {
  display: none;
}

.product-discount-btns-block label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 21px;
  height: 21px;
  border-radius: 4px;
  border: 1px solid transparent;
}

.product-discount-btns-block label:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 9px;
  background: #fff;
  height: 2px;
  margin-left: 5px;
}

.product-discount-btns-block label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  background: #fff;
  height: 9px;
  margin-top: 5px;
}

.product-discount-btns-block label.active {
  cursor: pointer;
}

.product-discount-btns-block label.active:active {
  background: #fff;
  border: 1px solid #000;
}

.product-discount-btns-block label.active:active:before, .product-discount-btns-block label.active:active:after {
  background: #000;
}

.product-discount-btns-block .product-discount-label-minus {
  background: rgba(255, 85, 0, 0.5);
}

.product-discount-btns-block .product-discount-label-minus:after {
  display: none;
}

.product-discount-btns-block .product-discount-label-plus {
  background: rgba(0, 0, 0, 0.5);
}

.product-discount-btns-block .product-discount-label-minus.active {
  background: #ff5500;
}

.product-discount-btns-block .product-discount-label-plus.active {
  background: black;
}

.product-discount-text {
  margin: 0 5px 0 0;
  color: #FF5500;
  font-size: 13px;
  font-family: "OpenSans SemiBold", sans-serif;
}

.product-discount-price {
  margin: 0 5px 0 0;
  color: #000;
  font-size: 13px;
  font-family: "OpenSans SemiBold", sans-serif;
}

.product-discount-percent {
  margin: 0;
  color: #2ea517;
  font-size: 13px;
  font-family: "OpenSans SemiBold", sans-serif;
}

/* kit series */
#kjseries {
  overflow-x: auto;
}

#kjseries thead {
  background: #fff !important;
}

#kjseries .kj-item {
  background: #fff !important;
}

#kjseries .btn_buy_text button {
  background: #333333;
  border: none;
  outline: none;
}

#kjseries .btn_buy_text button:hover {
  background: #222222;
}

#kjseries .btn_buy_text button i {
  margin-right: 2px;
}

/* product related */
.oct-carousel-row .oct-carousel-box {
  box-shadow: none;
}

.oct-carousel-header {
  font-family: 'OpenSans SemiBold';
  text-transform: initial;
}

.oct-carousel-header:after {
  display: none;
}

.oct-carousel-row .owl-carousel .owl-item .image:hover + .name a {
  text-decoration: none;
}

.oct-carousel-row .owl-carousel .owl-item .name a:hover {
  text-decoration: none;
}

.oct-carousel-row .owl-carousel .owl-item .name a {
  height: 62px;
}

.oct-bottom-cart-box .table > thead > tr > td {
  background: #FF5500;
  color: #fff;
}

#cart-table {
  margin-bottom: 8px;
}

#cart-table .input-group.btn-block {
  display: flex;
  align-items: center;
  max-width: initial !important;
}

#cart-table input[name="quantity"] {
  margin: 0;
}

#cart-table .btn-minus button i,
#cart-table .btn-plus button i {
  color: #FF5500;
}

#cart-table .btn-minus button,
#cart-table .btn-plus button {
  border: none;
  background: transparent;
}

.oct-bottom-cart-box .table .oct-bottom-cart-table-text {
  color: #2c2c2c;
}

#popup-cart-inner {
  /* number */
  /* btn minus plus */
}

#popup-cart-inner .item-quantity .input-group {
  display: flex;
  align-items: center;
  max-width: initial !important;
}

#popup-cart-inner .btn-minus,
#popup-cart-inner .btn-plus,
#popup-cart-inner input.plus-minus {
  display: block;
  min-width: initial;
  max-width: initial;
}

#popup-cart-inner .btn-minus button i,
#popup-cart-inner .btn-plus button i {
  color: #333333;
}

#popup-cart-inner .btn-minus button,
#popup-cart-inner .btn-plus button {
  border: none;
  background: transparent;
}

#popup-cart-inner .fa-shopping-cart {
  display: none;
}

.oct-cart-item > div.item-quantity input {
  padding-left: 3px;
  padding-right: 3px;
}

.oct-cart-item > div.item-price {
  margin-right: 16px;
}

.account-address-add .form-group.custom-field {
  display: none;
}

#quick-view {
  /* static price */
  /* summ */
  /* number */
  /* warning */
}

#quick-view .product-price-static {
  display: flex;
  align-items: flex-end;
}

#quick-view .product-price-static span {
  margin-right: 4px;
  font-size: 16px;
  font-family: "OpenSans SemiBold", sans-serif;
}

#quick-view .product-price-static h2 {
  margin: 0;
  font-size: 18px;
  line-height: 22px;
}

#quick-view .product-price .text-sum {
  margin-right: 4px;
  font-size: 16px;
  font-family: "OpenSans SemiBold", sans-serif;
}

#quick-view .product-price h3 {
  font-family: "OpenSans Regular", sans-serif;
}

#quick-view .number-wrap-popup {
  display: flex;
}

#quick-view .number-text {
  line-height: 18px;
  margin: 0 0 -2px;
  text-transform: capitalize;
  color: #333333;
  font-family: "OpenSans SemiBold", sans-serif;
  font-size: 16px;
}

#quick-view .number {
  margin: 0;
}

#quick-view .number input.plus-minus,
#quick-view .number .plus-minus-buffer,
#quick-view .number span {
  font-family: "OpenSans Regular", sans-serif;
}

#quick-view .text-danger {
  font-size: 13px;
}

#oneclick-popup h3 {
  text-align: left;
  color: #333333;
}

#oneclick-popup .product-price-static {
  display: flex;
  align-items: flex-end;
  margin: 0 0 9px;
}

#oneclick-popup .product-price-static span {
  margin-right: 4px;
  font-size: 16px;
  font-family: "OpenSans SemiBold", sans-serif;
}

#oneclick-popup .product-price-static #main-price-static.oneclick-main-price-static {
  margin: 0;
  line-height: 20px;
  font-size: 18px;
  font-family: "OpenSans Regular", sans-serif;
}

#oneclick-popup .product-price {
  display: flex;
  align-items: flex-end;
  margin: 0 0 11px;
}

#oneclick-popup .product-price span {
  margin-right: 4px;
  font-size: 16px;
  font-family: "OpenSans SemiBold", sans-serif;
}

#oneclick-popup .product-price #main-price.oneclick-main-price {
  margin-bottom: 0;
  line-height: 20px;
  font-family: "OpenSans Regular", sans-serif;
  font-size: 18px;
  color: #333333;
}

#oneclick-popup .payment-quantity {
  text-align: left;
}

#oneclick-popup .payment-quantity .number-wrap-popup-purchase {
  display: flex;
  align-items: flex-end;
}

#oneclick-popup .payment-quantity .number-wrap-popup-purchase .number-text {
  line-height: 18px;
  margin: 0;
  text-transform: capitalize;
  color: #333333;
  font-family: "OpenSans SemiBold", sans-serif;
  font-size: 16px;
}

#oneclick-popup .payment-quantity .number-wrap-popup-purchase .number {
  display: flex;
  padding: 0;
  margin: 0;
}

#oneclick-popup .payment-quantity .number-wrap-popup-purchase .number .oct-btn-disabled {
  display: none;
  opacity: 0.2;
}

#oneclick-popup .payment-quantity .number-wrap-popup-purchase .number .btn-minus,
#oneclick-popup .payment-quantity .number-wrap-popup-purchase .number .btn-plus {
  display: none;
}

#oneclick-popup .payment-quantity .number-wrap-popup-purchase .number .frame-change-count {
  display: flex;
  width: auto;
}

#oneclick-popup .payment-quantity .number-wrap-popup-purchase .number .frame-change-count .plus-minus-buffer {
  display: block;
  height: 18px;
  line-height: 17px;
  padding: 0;
  margin: 0 4px;
  background: none;
  font-family: "OpenSans Regular", sans-serif;
  font-size: 18px;
  color: #333333;
}

#oneclick-popup .payment-quantity .number-wrap-popup-purchase .number .frame-change-count input.plus-minus {
  display: none;
}

#oneclick-popup .payment-quantity .number-wrap-popup-purchase .number .frame-change-count span {
  display: block;
  font-size: 18px;
  line-height: 17px;
  color: #333333;
  font-family: "OpenSans Regular", sans-serif;
}

.contacthtml-box {
  padding: 0 0 20px;
}

.contact-container {
  display: flex;
  flex-flow: row wrap;
}

.contact-container .contact-item {
  width: calc(50% - 20px);
  margin: 0 20px 30px 0;
}

.contact-container .contact-item:nth-child(2n + 2) {
  width: 50%;
  margin: 0 0 30px 0;
}

.contact-container .contact-item .contact-department {
  display: block;
  margin: 0 0 14px;
  font-family: "OpenSans Bold", sans-serif;
  font-size: 17px;
}

.contact-container .contact-item .contact-caption {
  display: flex;
}

.contact-container .contact-item .contact-caption .contact-caption-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  background: #FF5500;
  margin: 0 24px 0 0;
}

.contact-container .contact-item .contact-caption .contact-caption-img span {
  color: #fff;
  font-size: 14px;
}

.contact-container .contact-item .contact-caption .contact-caption-info .contact-manager {
  display: block;
  font-size: 18px;
  margin: 0 0 9px;
}

.contact-container .contact-item .contact-caption .contact-caption-info .contact-phone {
  font-size: 18px;
}

.map-box {
  overflow: hidden;
}

.address-container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  padding: 38px 0;
  margin: 0 -10px;
}

.address-container:before {
  position: absolute;
  content: "";
  top: 0;
  left: 10px;
  width: calc(100% - 20px);
  height: 1px;
  background: #2c2c2c;
}

.address-container .address-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(35% - 20px);
  margin: 0 10px;
  padding: 30px 10px;
  background: #f2f2f2;
  text-align: center;
}

.address-container .address-item:nth-child(2) {
  width: calc(23% - 20px);
}

.address-container .address-item:nth-child(3) {
  width: calc(24% - 20px);
}

.address-container .address-item:nth-child(4) {
  width: calc(18% - 20px);
}

.address-container .address-item .address-title {
  margin: 0 0 18px;
  font-family: "OpenSans SemiBold", sans-serif;
  font-size: 22px;
  color: #FF5500;
}

.address-container .address-item .address-text {
  font-family: "OpenSans SemiBold", sans-serif;
  font-size: 22px;
  color: #2c2c2c;
  margin: 0 0 7px;
}

.address-container .address-item .address-socials {
  width: 100%;
  padding: 0 20px;
}

.address-container .address-item .address-socials ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.address-container .address-item .address-socials ul li {
  width: 25%;
}

.address-container .address-item .address-socials ul li a {
  display: block;
  padding: 8px;
  font-size: 15px;
}

.contact-form-row .contact-callback {
  padding: 48px 10px 48px 18px;
  background: #2c2c2c;
}

.contact-form-row .contact-callback .contact-callback-title {
  line-height: 26px;
  font-family: "OpenSans Bold", sans-serif;
  font-size: 22px;
  color: #fff;
}

.contact-form-row .contact-callback .contact-callback-text {
  font-family: "OpenSans Regular", sans-serif;
  font-size: 14px;
  color: #fff;
}

.contact-form-row .captcha-box {
  padding-left: 0;
}

.contact-form-row .contact-button-box {
  padding-left: 0;
}

.fieldset-inner-wrapper {
  display: flex;
  flex-flow: row wrap;
}

.fieldset-inner-wrapper .form-group {
  display: block;
}

.fieldset-inner-wrapper .form-group-name,
.fieldset-inner-wrapper .form-group-phone,
.fieldset-inner-wrapper .form-group-email {
  width: calc(33.3333% - 20px);
  margin: 0 20px 20px 0;
}

.fieldset-inner-wrapper .form-group-email {
  width: 33.3333%;
  margin: 0 0 20px 0;
}

.fieldset-inner-wrapper .form-group-inquiry {
  width: 100%;
  margin: 0 0 11px;
}

.panel-gost .panel {
  box-shadow: none;
}

.panel-gost .panel-default {
  border: none;
}

.panel-gost .panel-heading {
  background: #fff;
}

.panel-gost .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top: none;
}

.panel-gost a[data-toggle="collapse"]:after {
  content: "\f0d7";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin: 0 0 0 10px;
}

.panel-gost a[aria-expanded="false"] {
  color: #FF5500;
}

.panel-gost a[aria-expanded="false"]:after {
  content: "\f0d7";
  color: #FF5500;
}

.panel-gost a[aria-expanded="true"] {
  color: #000;
}

.panel-gost a[aria-expanded="true"]:after {
  content: "\f0d8";
  color: #000;
}

.panel-gost .table-wrapper {
  overflow-x: visible;
  overflow-y: hidden;
}

.panel-gost .table-wrapper td,
.panel-gost .table-wrapper th {
  border: 1px solid #000;
  padding: 6px;
}

.panel-gost .table-wrapper tr td:nth-child(3) {
  width: 138px;
}

.panel-gost .table-wrapper tr td:nth-child(3) span {
  display: inline-block;
  margin: 0 4px 0 0;
  color: #FF5500;
}

.panel-gost .table-wrapper tr td:nth-child(3) a {
  color: #FF5500;
}

.panel-gost .table-wrapper tr td:nth-child(4) {
  color: #FF5500;
}

.panel-gost .table-wrapper tr td:nth-child(4) span {
  display: inline-block;
  margin: 0 8px 0 0;
  color: #FF5500;
}

.panel-gost .table-wrapper tr td:nth-child(4) a {
  color: #FF5500;
}

.panel-gost .table-wrapper tr td:nth-child(4) p {
  margin: 0;
}

.about-us-text-background {
  background: #f2f2f2;
  padding: 25px 16px;
  margin: 51px 0 0;
  text-align: center;
}

.about-us-dif .about-us-dif-text {
  font-family: "OpenSans SemiBold", sans-serif;
  font-size: 22px;
  color: #FF5500;
}

.about-us-dif ul li {
  color: #FF5500;
}

.about-us-dif ul li span {
  color: #2c2c2c;
}

.badge-box i {
  color: #FF5500;
}

.news-row .product-grid .product-thumb h4 {
  height: 42px;
}

.news-row .product-grid .product-thumb p {
  text-align: left;
}

#popup-product-preorder-wrapper h3 {
  text-align: left;
  color: #333333;
}

#popup-product-preorder-wrapper .product-price {
  display: none;
}

#popup-product-preorder-wrapper .popup-promo-text {
  text-align: left;
}

body {
  font-family: 'OpenSans Regular', sans-serif;
  color: #2c2c2c;
}

#oct-bluring-box {
  background: #fff;
}

.row.sort-row {
  background: transparent;
}

a.wishlist.oct-button.current-link,
a.compare.oct-button.current-link {
  display: none !important;
}

a.wishlist.oct-button,
a.compare.oct-button {
  display: none !important;
}

a.oct-button.button-wishlist,
a.oct-button.button-compare {
  display: none !important;
}

#currency {
  display: none;
}

.oct-carousel-row .cart a {
  display: block;
  width: 80%;
}

h1.cat-header {
  font-family: 'OpenSans Bold', sans-serif;
  font-size: 36px;
  text-transform: initial;
}

.content-row {
  margin-top: 0;
  box-shadow: none;
}

.breadcrumb-box {
  padding: 16px 0 22px;
  margin: 0;
}

.breadcrumb-box .breadcrumb {
  padding: 4px 10px 4px 0;
}

#uptocall-mini {
  border: 10px solid rgba(175, 83, 21, 0.15);
}

@media screen and (max-width: 1200px) {
  /* header */
  /* phones */
  .phones-top-box .dropdown-toggle span {
    font-size: 14px;
  }
  .phone-list li a {
    font-size: 15px;
  }
  /* contact */
  .address-container .address-item {
    width: calc(50% - 20px);
    margin-bottom: 14px;
  }
  .address-container .address-item:nth-child(2), .address-container .address-item:nth-child(3), .address-container .address-item:nth-child(4) {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 992px) {
  /* header */
  header {
    padding: 86px 0 30px;
  }
  #m-wishlist,
  #m-compare {
    display: none;
  }
  .mobile-icons-box i {
    font-size: 56px;
  }
  /* menu */
  #menu .nav > li > a {
    color: #333333 !important;
  }
  /* common */
  .common-home .advantages-container .advantages-item {
    width: 33.3333%;
  }
  /* contact */
  .map-box {
    overflow: hidden;
    margin: 0 0 26px;
  }
  /* about us */
  .about-us-text-background {
    margin: 25px 0 0;
  }
  .about-us-img {
    margin: 0 0 20px;
  }
  /* filter */
  .mfilter-free-container {
    top: 58px;
  }
  .mfilter-free-button {
    margin: -1px 0 0 228px;
    border: 10px solid #FF5500;
    background: #FF5500 url(images/icons.png?v8) no-repeat -69px 0;
  }
}

@media screen and (max-width: 768px) {
  /* header */
  .mobile-icons-box i {
    margin: 30px 20px 0 0;
  }
  /* category */
  .product-grid .product-thumb h4 {
    height: 88px;
  }
  /* contact */
  .address-container .address-item {
    width: calc(100% - 20px);
    margin-bottom: 14px;
  }
  .address-container .address-item:nth-child(2), .address-container .address-item:nth-child(3), .address-container .address-item:nth-child(4) {
    width: calc(100% - 20px);
  }
  .address-container .address-item .address-text {
    margin: 0 0 15px;
  }
  .contact-form-row .contact-callback {
    padding: 20px 10px 20px 18px;
    margin: 0 0 48px;
    background: #2c2c2c;
  }
  .fieldset-inner-wrapper .form-group-email,
  .fieldset-inner-wrapper .form-group-name,
  .fieldset-inner-wrapper .form-group-phone {
    width: 100%;
    margin: 0 0 20px;
  }
  /* footer */
  .subscribe-btn-block {
    padding: 0;
  }
}

@media screen and (max-width: 576px) {
  /* common */
  .common-home .advantages-container .advantages-item {
    width: 50%;
  }
  /* contact */
  .contact-container .contact-item {
    width: 100%;
    margin: 0 0 30px;
  }
  .contact-container .contact-item:nth-child(2n + 2) {
    width: 100%;
    margin: 0 0 30px;
  }
}

@media screen and (max-width: 480px) {
  /* header */
  header {
    padding: 76px 0 25px;
  }
  .mobile-icons-box i {
    font-size: 28px;
    margin: 30px 20px 0 0;
  }
  /* common */
  .common-home .cat-wall-row .cat-wall-row-side-text {
    padding: 26px 10px 26px 18px;
    margin: 0 0 32px;
  }
  .common-home .cat-wall-row .cat-wall-row-items .cat-wall-row-item-wrapper {
    margin: 0;
  }
  .common-home .cat-wall-row .cat-wall-row-items .cat-wall-row-item-wrapper .cat-wall-row-item {
    height: 140px;
  }
  .common-home .advantages-container .advantages-item {
    width: 100%;
  }
  /* footer */
  footer .third-row .f-acc-toggle:before, footer .third-row .f-acc-toggle:after {
    background: #FF5500;
  }
  footer .footer-middle .first-row .h5 {
    text-align: left;
  }
}

@media screen and (max-width: 320px) {
  /* header */
  header {
    padding: 50px 0 16px;
  }
}

@media screen and (min-width: 992px) {
  /* popup purchase */
  .discounts-row {
    min-width: 700px;
  }
  .ts-logo-box img {
    width: 160px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  /* category */
  .product-grid .product-thumb:hover h4 a {
    text-decoration: none;
  }
  /* hover effect */
  .product-grid .product-thumb:hover .cat-box-effect-inner {
    transform: unset !important;
  }
  .product-grid .product-thumb:hover .oct-product-stock {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  /* header */
  .dropdown.phones-dropdown {
    margin-left: 15px;
  }
}
